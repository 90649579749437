import { gettextOrUndef } from '@/js/utils/localization.js';

/**
 * Wrapper confirmation popover whith Yes/No buttons.
 * @param {string} title, Custom text or HTML
 * @param {function} confirmFn, Will be called upon confirmation.
 * @param {function} cancelFn, Will be called upon cancel.
 * @param {object} options, Popover options
 *
 * usage:
    $(this).customConfirm('Headline text', () => {
        // do something
    });
 */
export default function customConfirm( title, confirmFn, cancelFn, options ) {
    var title = title || '';
    var id    = guidGenerator();

    // если забыли передать cancelFn, но передали объект options
    if ( cancelFn && typeof cancelFn !== "function" ) options = cancelFn;
    options = options || {};

    if ( options.isHtml    === void 0 ) options.isHtml    = true;
    if ( options.placement === void 0 ) options.placement = 'auto';
    if ( options.title     === void 0 ) options.title     = 'Confirm?';
    if ( options.trigger   === void 0 ) options.trigger   = 'click';
    if ( options.textYes   === void 0 ) options.textYes = gettextOrUndef('tpl:common:yes') || 'Yes';
    if ( options.textNo    === void 0 ) options.textNo  = gettextOrUndef('tpl:common:no')  || 'No';

    this
        .click( function( e ) {
            e.preventDefault();
            e.stopPropagation();
        } )
        .popover( {
            trigger   : options.trigger,
            placement : options.placement,
            title     : title || options.title,
            html      : options.isHtml,
            content   : options.content || `<a class="btn btn-gradient-01 btn-sm mr-1 js-confirm-%s" tabindex="1">${ options.textYes }</a> <a class="btn btn-gradient-04 btn-sm js-cancel-%s" tabindex="2">${ options.textNo }</a>`.replace( /%s/g, id) ,
        } );

    if ( typeof confirmFn === 'function' ) {
        $('body').on( 'click', '.js-confirm-' + id, confirmFn );
    }

    if ( typeof cancelFn  === 'function' ) {
        $('body').on( 'click', '.js-cancel-' + id, cancelFn );
    }

    return this;
}

/**
 * Generate randome id for div.
 */
function guidGenerator() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}
