/**
 * Debounce helper
 *
 * @example
 * var logging = debounce(function(){
 *     alert("Heavy task");
 * }, 5000);
 */
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this,
            args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


/**
 * Throttle helper
 *
 * @example
 * var logging = throttle(function(){
 *     alert("mousemove events");
 * }, 100);
 */
export function throttle(func, wait) {
    var isThrottled = false,
        context,
        args;

    function wrapper() {
        if (isThrottled) {
            args = arguments;
            context = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function() {
            isThrottled = false;
            if (args) {
                wrapper.apply(context, args);
                args = context = null;
            }
        }, wait);
    }

    return wrapper;
}

export function formatDatetime(value, format) {
    if (value) {
        if ( format ) {
            return moment(value).toClient().format(format);
        } else {
            return moment(value).toClient().fromNow();
        }
    }
};
