import { langToShort, langMap } from '@/js/utils/lang.js';

window.moment  = require('moment');
window.Cookies = require('js-cookie');

/**
 * Langs from https://github.com/moment/moment/tree/develop/locale
 */
Object.values( langMap ).forEach( key => {
    if ( 'en' === key ) {
        return;
    }

    require(`moment/locale/${ key }`);
});


export default {
    init() {
        window.lang = Cookies.get('lang') || 'eng';

        window.moment.locale( langToShort( lang ) );

        window.moment.fn.toClient = function() {
            var tm = Cookies.get('tm');
            this.add(tm, 'm');
            return this;
        };

        window.moment.fn.toServerFormat = function() {
            return this.utc().format('YYYY-MM-DDTHH:mm:ss');
        };
    }
}
