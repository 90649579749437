export function getLangFromPath( pathname ) {
    if (typeof pathname !== 'string') {
        return '';
    }

    let urlLangMatch = pathname.match(/(^\/)(\w{2})(\/|$)/);

    return urlLangMatch ? urlLangMatch[2] : '';
}

/**
 * Проверяет lang в пути, если нет - доабвляет из куков (из переменной window.lang)
 * Например:
 *  из /promo/freedoge    -> /en/promo/freedoge
 *  из /en/promo/freedoge -> без изменений
 * @param {string} pathname Путь в URL
 * @returns {string} Путь в URL с учетом установленного языка
 */
export function pathByLang( pathname ) {
    let urlLang = getLangFromPath( pathname );

    if ( !urlLang ) {
        pathname = `/${ langToShort( window.lang ) }${ pathname }`;
    }

    return pathname;
}

export function langToShort( lang ) {
    return langMap[ lang ] || 'en';
}

export const langMap = {
    eng : 'en',
    rus : 'ru',
    tha : 'th',
    msa : 'my',
    ind : 'id',
    jpn : 'ja',
    kor : 'ko',
    chn : 'zh-cn',
    twn : 'zh-tw',
};
