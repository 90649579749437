import axios from 'axios';
require('webfont.font.js');
import jsParams from '@/js/utils/js-params.js';
import { gettext, gettextOrUndef } from '@/js/utils/localization.js';
import 'lang-switcher.js';
import momentSetup from 'moment-setup.js';
import { pathByLang } from '@/js/utils/lang.js';
import { formatMoney } from '@/js/utils/format-money.js';
import { formatDatetime } from '@/js/utils/common.js';
import customConfirm from '@/js/utils/custom-confirm.js';

import 'swiper/dist/css/swiper.css';

(function($){
    $.fn.customConfirm = customConfirm;
})(jQuery);

momentSetup.init();

window.fixCurrencyCode = function(currency) {
    return CurrencyCodeFixMap[currency] || currency;
};

// isMobile
(function() {
    function _updateIsMobile() {
        window.isMobile = $(window).width() <= 576;
    }

    $(window).resize( _updateIsMobile );

    _updateIsMobile();
})();

/* Axios global settings */
axios.defaults.headers.common = { 'X-Requested-With' : 'XMLHttpRequest' };
axios.defaults.validateStatus = function (status) {
    return status < 500;
}

axios.interceptors.response.use( function ( response ) {
    if ( response.status === 401 ) {
        return window.location.reload();
    }

    if ( response.data && response.data.redirect ) {
        /**
         * External redirect without pathByLang
         */
        if ( /^http.*/.test( response.data.redirect ) ) {
            window.location.href = response.data.redirect;

            return response;
        }

        window.location.href = pathByLang( response.data.redirect );
    }

    return response;
}, function ( error ) {
    if ( error.response.status === 401 ) {
        return window.location.reload();
    }

    return Promise.reject( error );
});
/* /Axios global settings */

/* jQuery global settings */
window.$.ajaxSetup({
    beforeSend: function (xhr)
    {
       xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    }
});

window.$( document ).ajaxComplete( function( event, xhr, settings ) {
    if ( xhr.status === 401 ) {
        window.location.reload();
    }

    if ( xhr.responseJSON && xhr.responseJSON.redirect ) {
        if ( /^http.*/.test( xhr.responseJSON.redirect ) ) {
            window.location.href = xhr.responseJSON.redirect;

            return;
        }

        window.location.href = pathByLang( xhr.responseJSON.redirect );
    }
});
/* /jQuery global settings */

// window.loader = ( function() {
//     function Loader( time, callback ) {};

//     Loader.prototype = {
//         constructor: Loader,

//         show( time, callback ) {
//             $('.loader').fadeIn( time, callback );
//         },

//         hide( time, callback ) {
//             $('.loader').fadeOut( time, callback );
//         },
//     }

//     return new Loader();
// } )();


$(document)
    .on('click', '.dropdown-menu-form', function(e) {
        e.stopPropagation();
    });

/* Ready */
$(function(){
    // customConfirm handler
    $('body').on('click', function (e) {
        if ( $(e.target).attr('data-toggle') !== 'popover' && !$(e.target).parent('.popover.show').length ) {
            $('[data-toggle="popover"]').popover('hide');
        }
    });


    $(window).scroll(function() {
        var $header = $('.navbar.header');

        $header.length && $header.toggleClass('header_type_collapse', !!$header.offset().top);
    });

    // ===== Scroll to Top ====
    $(window).scroll(function() {
        if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
            $('#return-to-top').fadeIn(200);    // Fade in the arrow
        } else {
            $('#return-to-top').fadeOut(200);   // Else fade out the arrow
        }
    });

    $('#return-to-top').click(function() {      // When arrow is clicked
        $('body,html').animate({
            scrollTop : 0                       // Scroll to top of body
        }, 500);
    });


    Cookies.set('tm', (0-(new Date()).getTimezoneOffset()), { expires: 365 });

    /* Date/time formatting + timezone offset */
    $('.j_date').each(function(event) {
        $(this).text(formatDate($(this).text()));
    });
    $('.j_datetime').each(function(event) {
        $(this).text(formatDatetime($(this).text()));
    });
    $('.j_datetime_utc').each(function(event) {
        $(this).text(formatDatetimeUtc($(this).text()));
    });
    $('.j_time').each(function(event) {
        $(this).text(formatTime($(this).text()));
    });
    /* /Date/time formatting + timezone offset */
});

function isLatinAndSymbols(value) {
    return /^[a-zA-Z0-9$@$!%*?&#^/\-_., +\(\)=]*$/.test(value);
}

/**
 * Uppercase first literal an EVERY words: 'my world' -> 'My World'
 * @param {string} string
 * @returns {string}
 */
function firstUppercase( string ) {
    if ( !string ) return '';

    return string.split(/\s+/).map( word => word[0].toUpperCase() + word.substring(1) ).join(' ');
}

function removeZeros( string ) {
    if ( !string ) return '';

    return /\./.test('' + string) ? ('' + string).replace(/0+$/, '').replace(/\.$/, '') : string;
}

/**
 * Facebook Pixel Code
 * Example:
 *     <button data-fb-track="StartRegistration">Submit</button>
 */
$('html body').on('click', '[data-fb-track]', function(e) {
    window.fbq( 'track', $( e.target ).data('fb-track') );
})

if ( window.isDev && !window.fbq ) {
    /**
     * Mock fbq tracker
     */
    window.fbq = (a,b) => console.log('Facebook Pixel', a, b);
}

window.fbq = (a,b) => function(){};

// For Vue <popover />
function closePopover() {
    document.body.click();
}

function getCommonError(error) {
    return gettext(`error:common:${error}`);
}

function getCurrency(key) {
    const curConf = jsParams().currency_config || {}
    return curConf[key] || {};
}

function featureIsEnabled(key) {
    const featureList = jsParams().features_list || {}
    return featureList[key] || false;
}

/**
 * Convert string to PascaleCase
 * @param {string} String
 * @returns {string} PascaleCase string
 * @example
 *  'foo bar'                -> 'FooBar'
    'Foo Bar'                -> 'FooBar'
    'fooBar'                 -> 'FooBar'
    'FooBar'                 -> 'FooBar'
    '--foo-bar--'            -> 'FooBar'
    '__FOO_BAR__'            -> 'FooBar'
    '!--foo-¿?-bar--121-**%' -> 'FooBar121'
 */
export function toPascalCase(string = '') {
    return `${string}`
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w+)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, 'g'), '')
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}

function formatCurrency(value, digits = 8) {
    return value != null ? parseFloat(value).toFixed(digits).replace(/0+$/, '').replace(/\.$/, '') : '-';
}

function formatPercent(value) {
    var value = parseFloat(value),
        sign  = value > 0 ? '+' : '';
    return sign + value.toFixed(2).replace(/0+$/, '').replace(/\.$/, '') + '%';
}

/* Vue */
const globalMixin = {
    methods: {
        gettext,
        gettextOrUndef,
        getCommonError,
        getCurrency,
        featureIsEnabled,
        formatMoney,
        jsParams,
        isLatinAndSymbols,
        firstUppercase,
        closePopover,
        pathByLang,
        toPascalCase,
        firstUppercase,
        removeZeros,
        fixCurrencyCode,
        formatDatetime,
        formatCurrency,
        formatPercent,
        moment,
    },
};

window.vueGlobalMixin = globalMixin;
/* /Vue */


Object.assign( window, {
    axios,
    gettext,
    gettextOrUndef,
    getCommonError,
    getCurrency,
    featureIsEnabled,
    jsParams,
    formatMoney,
    formatDatetime,
    isLatinAndSymbols,
    firstUppercase,
    removeZeros,
    toPascalCase,
} );
