import jsParams from '@/js/utils/js-params.js'

const getTranslate = function( hasUndef ) {
    return function ( localesKey ) {
        let params = Array.prototype.slice.call( arguments, 1 ),
            text   = jsParams().locales && jsParams().locales[ localesKey ];

        if ( hasUndef && !text ) {
            // вернем undefied если нет ключа
            return void 0;
        }

        text = text || localesKey && localesKey.toString() || '';

        return text.replace( /%s/gi, () => {
            let param = params.shift();

            if ( param === void 0 ) param = '';

            return param;
        });
    }
}

export const gettext = getTranslate();

export const gettextOrUndef = getTranslate( true );
