import { getLangFromPath } from '@/js/utils/lang.js';

$(function(){
    $('.j-change-lang').click(function(e) {
        let pathname     = location.pathname || '';
        let urlLang      = getLangFromPath( pathname );
        let regexpOldLang;

        e.preventDefault();

        let newLang = $(this).attr('lang');

        if ( !newLang  ) {
            return;
        }

        /**
         * Replace lang in URL
         */
        if ( urlLang ) {
            regexpOldLang = new RegExp( `(^\/)(${ urlLang })` );
            pathname      = pathname.replace( regexpOldLang, '/' + newLang );
        }
        /**
         * Append lang in URL
         */
        else {

            pathname = pathname.split('/');
            pathname = `/${pathname[pathname.length -1]}`;

            pathname = `/${ newLang }${ pathname }`;
        }

        return document.location = location.origin + pathname;
    });
});
