/**
 * Возвращает копию объекта JS_PARAMS (по умолчанию), либо сам объект.
 * @param {boolean} isClone Default: true. При false - будет передаваься по ссылке.
 */
export default function jsParams( isClone ) {
    if ( isClone === void 0 ) isClone = true;

    window.JS_PARAMS = window.JS_PARAMS || {};

    if ( isClone && $ ) {
        return $.extend( true, {}, window.JS_PARAMS );
    }

    return window.JS_PARAMS;
};
