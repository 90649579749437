import { BigNumber } from 'bignumber.js';

export const formatMoneyConfig = {
    precision : {
        BCH    : 8,
        BTC    : 8,
        BTG    : 8,
        DSH    : 8,
        EOS    : 8,
        ETC    : 18,
        ETH    : 18,
        BNB    : 18,
        EMC    : 6,
        EUR    : 2,
        GBP    : 2,
        GNO    : 8,
        GNT    : 8,
        ICN    : 8,
        LTC    : 8,
        MLN    : 8,
        REP    : 8,
        RUB    : 2,
        USD    : 2,
        USDT   : 2,
        XDG    : 8,
        XMR    : 8,
        XRP    : 8,
        XVG    : 8,
        ZEC    : 8,
        USDT20 : 6,
        TEST   : 6,
    },

    symbols : {
        USD : '$',
        EUR : '€',
    },

    default : {
        precision : 2,
        separator : ' ',
        roundMode : 'up',
    },
}

/**
 * Formatting money with currency.
 * @example formatMoney( 1230.01, 'USD', { withCurrency: 1, withoutZeros: 1, withoutTriads: 1 })
 * @param {string, number} value The value of a money.
 * @param {string} currency The currency of a money. Default: 'BTC'.
 * @param {object} params Options for formater:
 *  @param {boolean} withoutTriads, params option, Triads off.
 *  @param {boolean} withoutZeros,  params option, Zeros off.
 *  @param {boolean} withCurrency,  params option, Added postfix currency code.
 *  @param {boolean} withSymbol,    params option, Added prefix currency symbol.
 *  @param {strinng} roundMode,     params option, 'up' (default), 'down'.
 *  @param {number} precision,      params option, Set custom precision.
 * @returns {string} Return formatted numbers, examles: 0.00100000 (for BTC), 1,234.56 (for USD, EUR).
 */
export function formatMoney( value, currency, params ) {
    var params    = params || {};
    var currency  = currency || '';
    var precision = params.precision || formatMoneyConfig.precision[ currency ] || formatMoneyConfig.default.precision || 2;
    var separator = separator || formatMoneyConfig.default.separator || ' ';
    var symbol    = formatMoneyConfig.symbols[ currency ] || currency + separator;
    var roundModeMap = {
        'down' : 1, // ROUND_DOWN. Rounds towards zero
        'up'   : 4, // ROUND_HALF_UP. Rounds towards nearest neighbour. If equidistant, rounds away from zero
    };

    var roundMode = roundModeMap[ params.roundMode ] || roundModeMap[ formatMoneyConfig.default.roundMode ] || 4;

    if ( typeof value === 'undefined' ) {
        return '';
    }

    value = new BigNumber( value );
    value = value.toFixed( precision, roundMode );

    if ( !params.withoutTriads && precision <= 2 ) {
        // Format triads. Fiat only (USD, EUR, etc.)
        value = value.toString().replace(/\d(?=(\d{3})+\.)/g, '$& ');
    }
    else if ( params.withoutZeros ) {
        // Remove trailing zeros. Cryptocurrency only
        value = value.toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');
    }

    // examples: 120.15 USD, 1.0232 BTC
    if ( params.withCurrency ) {
        value = value.toString() + separator + currency;
    }

    // examples: $120 040.15, BTC 1.0232
    if ( params.withSymbol ) {
        value = symbol + value.toString();
    }

    return value;
}
